'use client';

import { useEffect, useState } from 'react';

import Navbar from '@/layouts/Navbar/Navbar';

import styles from './HamburgerMenu.module.css';

const HamburgerMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleOnLinkClick = () => {
    setIsMenuOpen(false);
  };
  return (
    <div>
      <button
        type="button"
        className={styles.hamburgerMenu}
        onClick={toggleMenu}
        aria-label="Toggle menu"
      >
        {isMenuOpen ? (
          <span className={styles.xIcon} />
        ) : (
          <>
            <div className={styles.line} />
            <div className={styles.line} />
            <div className={styles.line} />
          </>
        )}
      </button>
      {isMenuOpen && <Navbar handleOnClick={handleOnLinkClick} />}
    </div>
  );
};

export default HamburgerMenu;
