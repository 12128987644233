'use client';

import type { FC } from 'react';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import type { UIContextType, UIProviderProps } from './UIContext.types';

const defaultContextValue: UIContextType = {
  headerHeight: 0,
  setHeaderHeight: () => {},
};

const UIContext = createContext<UIContextType>(defaultContextValue);

const UIProvider: FC<UIProviderProps> = ({ children }) => {
  const [headerHeight, setHeaderHeight] = useState<number>(
    defaultContextValue.headerHeight,
  );

  useEffect(() => {
    if (headerHeight)
      document.documentElement.style.setProperty(
        '--header-height',
        `${headerHeight - 4}px`,
      );
  }, [headerHeight]);

  const contextValue = useMemo(
    () => ({
      headerHeight,
      setHeaderHeight,
    }),
    [headerHeight],
  );

  return <UIContext.Provider value={contextValue}>{children}</UIContext.Provider>;
};

const useUIContext = () => useContext(UIContext);

export { UIProvider, useUIContext };
