'use client';

import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useRef } from 'react';

import HamburgerMenu from '@/components/HamburgerMenu/HamburgerMenu';
import { useUIContext } from '@/context/UIContext/UIContext';
import anashimIL from '@/public/assets/icons/anashimIL.svg';
import anashimInnovationHub from '@/public/assets/icons/anashimInnovationHub.svg';

import Navbar from '../Navbar/Navbar';
import styles from './Header.module.css';

const Header = () => {
  const headerRef = useRef<HTMLHeadingElement>(null);
  const { setHeaderHeight } = useUIContext();

  useEffect(() => {
    const updateHeaderHeight = () => {
      if (headerRef.current) {
        setHeaderHeight(headerRef.current.offsetHeight);
      }
    };

    window.addEventListener('resize', updateHeaderHeight);
    updateHeaderHeight();

    return () => {
      window.removeEventListener('resize', updateHeaderHeight);
    };
  }, []);
  return (
    <header ref={headerRef} className={styles.header}>
      <div className={styles.navbarMobileView}>
        <HamburgerMenu />
      </div>
      <Image
        className={styles.anashimIL}
        loading="lazy"
        src={anashimIL}
        width={88}
        height={45}
        alt="anashimIL logo"
      />
      <div className={styles.navbarDesktopView}>
        <Navbar />
      </div>
      <Link href="/">
        <Image
          width={373}
          height={50}
          src={anashimInnovationHub}
          loading="lazy"
          className={styles.anashimInnovationHub}
          alt="anashimInnovationHub logo"
        />
      </Link>
    </header>
  );
};

export default Header;
