import(/* webpackMode: "eager" */ "/codebuild/output/src2857006744/src/anashim-full/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2857006744/src/anashim-full/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2857006744/src/anashim-full/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2857006744/src/anashim-full/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2857006744/src/anashim-full/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2857006744/src/anashim-full/public/assets/icons/footer/appleseeds.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2857006744/src/anashim-full/public/assets/icons/footer/innovation-authority.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2857006744/src/anashim-full/public/assets/icons/footer/ministry-of-economy.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2857006744/src/anashim-full/public/assets/icons/footer/national-digital-agency.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2857006744/src/anashim-full/src/app/global.css");
import(/* webpackMode: "eager" */ "/codebuild/output/src2857006744/src/anashim-full/src/app/Layout.module.css");
import(/* webpackMode: "eager" */ "/codebuild/output/src2857006744/src/anashim-full/src/context/UIContext/UIContext.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src2857006744/src/anashim-full/src/layouts/Footer/Footer.module.css");
import(/* webpackMode: "eager" */ "/codebuild/output/src2857006744/src/anashim-full/src/layouts/Header/Header.tsx")